import React, { memo } from 'react';
import { Dropdown } from 'react-bootstrap';
import cn from 'classnames';

import { Theme } from 'uikitv2/common/types';
import { DropdownToggle } from '../DropdownToggle';
import { DropdownMenu } from '../DropdownMenu';
import { DropdownCoreProps } from './types';
import classes from '../Dropdown.module.scss';

export const DropdownCore = memo<DropdownCoreProps>(({
  children,
  label,
  classNameDropdownToggle,
  classNameDropdownMenu,
  className,
  classNameIconToggle,
  autoClose = true,
  theme = Theme.dark,
  onToggle,
  align = 'start',
  showArrow = false,
}: DropdownCoreProps) => (
  <Dropdown autoClose={autoClose} className={cn(className, classes[theme])} align={align} onToggle={onToggle}>
    <Dropdown.Toggle
      id="dropdown-basic"
      className={cn(classes.dropdown, classNameDropdownToggle)}
      as={DropdownToggle}
      showArrow={showArrow}
      classNameIcon={classNameIconToggle}
    >
      {label}
    </Dropdown.Toggle>
    <Dropdown.Menu
      className={cn(classes['dropdown-menu'], classNameDropdownMenu)}
      as={DropdownMenu}
    >
      {children}
    </Dropdown.Menu>
  </Dropdown>
));
