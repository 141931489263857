import {
  memo, useCallback, useState, useMemo,
} from 'react';
import cn from 'classnames';
import { usePathname } from 'next/navigation';
import dynamic from 'next/dynamic';
import { Box } from 'uikitv2/Box';
import { Dropdown } from 'uikitv2/Dropdown';
import { useAppSelector } from 'lib/hooks';
import { formBuildOffersCountSelector } from 'lib/features/createOrderV2/selectors';
import { itemsMarketplace, itemsMyContent, getActiveRoute } from './helpers';
import { MenuProps } from './types';
import { NavLink } from './NavLink';
import classes from './Menu.module.scss';

const CircleCounter = dynamic(() => import('../../../uikitv2/CircleCounter/CircleCounter'), { ssr: false });

export const Menu = memo(({ isUserConnected }: MenuProps) => {
  const pathname = usePathname();
  const [isShowMarketplace, setIsShowMarketplace] = useState(false);
  const [isShowMyContent, setIsShowMyContent] = useState(false);
  const count = useAppSelector(formBuildOffersCountSelector);
  const activeRoute = useMemo(() => getActiveRoute(pathname), [pathname]);
  const onToggleMarketplace = useCallback((show: boolean) => {
    setIsShowMarketplace(show);
  }, []);

  const onToggleMyContent = useCallback((show: boolean) => {
    setIsShowMyContent(show);
  }, []);

  const renderToggleContent = useCallback((content: string) => {
    return (
      <div>
        {content}
      </div>
    );
  }, []);

  return (
    <Box className={classes.groups}>
      <Box className={classes.group} alignItems="center">
        <Dropdown
          items={itemsMarketplace}
          renderToggleContent={() => renderToggleContent('Marketplace')}
          classNameDropdownToggle={cn(
            classes['dropdown-toggle'],
            { [classes.white]: activeRoute.marketplace || isShowMarketplace },
          )}
          classNameDropdownMenu={classes['dropdown-menu']}
          classNameMenuItem={classes['dropdown-menu-item']}
          className={classes.dropdown}
          onToggle={onToggleMarketplace}
          dataTestId="dropdown-marketplace"
          id="dropdown-marketplace"
        />
        {isUserConnected && (
          <Dropdown
            items={itemsMyContent}
            renderToggleContent={() => renderToggleContent('My Content')}
            classNameDropdownToggle={cn(
              classes['dropdown-toggle'],
              { [classes.white]: activeRoute.mycontent || isShowMyContent },
            )}
            classNameDropdownMenu={classes['dropdown-menu']}
            classNameMenuItem={classes['dropdown-menu-item']}
            className={classes.dropdown}
            onToggle={onToggleMyContent}
            dataTestId="dropdown-my-files"
            id="dropdown-my-files"
          />
        )}
      </Box>
      <Box className={classes.group} alignItems="center">
        {isUserConnected && <NavLink href="/all-orders">All orders</NavLink>}
        <NavLink href="/order-create">
          <span className={classes['text-link']}>Order</span>
          <CircleCounter counter={count} className={classes.counter} />
        </NavLink>
      </Box>
    </Box>
  );
});
