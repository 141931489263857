import {
  useState, useCallback, useRef, useEffect, useMemo,
} from 'react';
import cn from 'classnames';
import { StorageType } from '@super-protocol/dto-js';

import { useAppDispatch } from 'lib/hooks';
import { Box } from 'uikitv2/Box';
import { addErrorNotification, addSuccessNotification } from 'lib/features/notifications';
import { Storage as StorageEnum } from 'components/Account/types';
import { Button } from 'uikit/Buttons/Button';
import { useCheckUserSettingsAndStorage } from 'hooks/useCheckUserSettingsAndStorage';
import { useStorage } from 'hooks/storage/useStorage';
import { useUserSettings } from 'hooks/user/useUserSettings';
import { trackEvent } from 'lib/features/events/thunks';
import getConfig from 'config';
import { getDefaultErrorProperty } from 'lib/features/events/helpers';
import { getErrorMessage } from 'utils';
import { settings } from './helpers';
import { RadioGroup } from '../RadioGroup';
import { StorjAccount } from './StorAccount';
import { StorjAccountRef, FormValues as StorjAccountFormValues } from './StorAccount/types';
import classes from '../Web3Account.module.scss';

export const Storage = () => {
  const [loadingForm, setLoadingForm] = useState(false);
  const dispatch = useAppDispatch();
  const storjAccountFormRef = useRef<StorjAccountRef>(null);
  const {
    data: userSettings,
    loading: loadingUserSettings,
    refetch: refetchUserSettings,
  } = useUserSettings();

  const {
    data: activeStorage,
    loading: loadingActiveStorage,
  } = useStorage(userSettings?.activeStorageId);

  const {
    data: decentralizedStorage,
    loading: loadingDecentralizedStorage,
  } = useStorage(userSettings?.decentralizedStorageId);

  const {
    setCentralizedStorageToUserSettings,
    createAndSetDecentralizedStorageToUserSettings,
  } = useCheckUserSettingsAndStorage();

  const [selectedStorageType, setSelectedStorageType] = useState(StorageEnum.SP_CLOUD);
  const { id, title, data } = settings;

  const onChange = useCallback((evt) => {
    setSelectedStorageType(evt.target.value);
  }, []);

  const onError = useCallback((e: Error) => {
    dispatch(addErrorNotification(getErrorMessage(e)));
  }, [dispatch]);

  const onSuccess = useCallback(() => {
    refetchUserSettings();
    dispatch(addSuccessNotification('Storage changed successfully'));
  }, [dispatch, refetchUserSettings]);

  const submitCentralizedAccount = useCallback(async () => {
    try {
      setLoadingForm(true);
      await setCentralizedStorageToUserSettings();
      dispatch(trackEvent({
        eventType: 'user_area_storage_centralized_added',
        property: { result: 'success' },
      }));
      onSuccess();
    } catch (e) {
      dispatch(trackEvent({
        eventType: 'user_area_storage_centralized_added',
        property: getDefaultErrorProperty(e as Error),
      }));
      onError(e as Error);
    } finally {
      setLoadingForm(false);
    }
  }, [setCentralizedStorageToUserSettings, onError, onSuccess, dispatch]);

  const submitStorjAccountForm = useCallback(async (values: StorjAccountFormValues) => {
    try {
      setLoadingForm(true);
      const {
        bucket, writeAccessKeyId, writeSecretAccessKey, readAccessKeyId, readSecretAccessKey, prefix,
      } = values as Required<StorjAccountFormValues>;
      await createAndSetDecentralizedStorageToUserSettings({
        storageType: StorageType.S3,
        bucket,
        prefix: prefix || '',
        s3Credentials: {
          region: getConfig().NEXT_PUBLIC_S3_REGION,
          writeAccessKeyId,
          writeSecretAccessKey,
          readAccessKeyId,
          readSecretAccessKey,
        },
      });
      dispatch(trackEvent({
        eventType: 'user_area_storage_added',
        property: { result: 'success' },
      }));
      onSuccess();
    } catch (e) {
      dispatch(trackEvent({
        eventType: 'user_area_storage_added',
        property: getDefaultErrorProperty(e as Error),
      }));
      onError(e as Error);
    } finally {
      setLoadingForm(false);
    }
  }, [createAndSetDecentralizedStorageToUserSettings, onError, onSuccess, dispatch]);

  const onSave = useCallback(async () => {
    if (selectedStorageType === StorageEnum.STORJ_ACCOUNT) {
      storjAccountFormRef.current?.submit();
    } else {
      await submitCentralizedAccount();
    }
  }, [selectedStorageType, submitCentralizedAccount]);

  const initialValuesStorj = useMemo<StorjAccountFormValues>(() => {
    if (!decentralizedStorage) return {};
    const { s3Credentials, bucket, prefix } = decentralizedStorage;
    const {
      writeAccessKeyId, writeSecretAccessKey, readAccessKeyId, readSecretAccessKey,
    } = s3Credentials || {};
    return {
      bucket,
      writeAccessKeyId,
      writeSecretAccessKey,
      readAccessKeyId,
      readSecretAccessKey,
      prefix,
    };
  }, [decentralizedStorage]);

  const loading = loadingActiveStorage || loadingDecentralizedStorage || loadingUserSettings || loadingForm;

  useEffect(() => {
    setSelectedStorageType(!activeStorage || activeStorage?.isCentralized ? StorageEnum.SP_CLOUD : StorageEnum.STORJ_ACCOUNT);
  }, [activeStorage]);

  return (
    <Box
      className={cn(classes['settings-section'])}
      direction="column"
    >
      <RadioGroup
        {...{
          data,
          selected: selectedStorageType,
          id,
          onChange,
          title,
          disabled: loading,
        }}
      />
      {selectedStorageType === StorageEnum.STORJ_ACCOUNT && (
        <StorjAccount
          ref={storjAccountFormRef}
          disabled={loading}
          onSubmit={submitStorjAccountForm}
          initialValues={initialValuesStorj}
        />
      )}
      <Box justifyContent="center">
        <Button
          type="submit"
          disabled={loading}
          className={classes['btn-create-provider']}
          onClick={onSave}
          data-testid="button-save-account"
        >
          Save
        </Button>
      </Box>
    </Box>
  );
};
