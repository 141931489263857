import { memo } from 'react';
import cn from 'classnames';

import { Box } from 'uikitv2/Box';
import classes from './ModalTitle.module.scss';
import { ModalTitleProps } from './types';

export const ModalTitle = memo<ModalTitleProps>(({ title, className }) => {
  return (
    <Box justifyContent="center" alignItems="center" className={cn(classes.wrap, className)}>
      <h3 className={classes.title}>{title}</h3>
    </Box>
  );
});