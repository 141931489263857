'use client';

import DropdownCore from 'react-bootstrap/Dropdown';
import {
  memo, useCallback, useMemo, forwardRef,
} from 'react';
import cn from 'classnames';
import Link from 'next/link';

import { Box } from 'uikitv2/Box';
import { DropdownToggle } from './DropdownToggle';
import { DropdownMenu } from './DropdownMenu';
import { DropdownProps } from './types';
import classes from './Dropdown.module.scss';

export const Dropdown = memo(forwardRef<HTMLDivElement, DropdownProps>(({
  items = [],
  active,
  classNameDropdownToggle,
  classNameDropdownMenu,
  classNameMenuItem,
  className,
  classNameIconToggle,
  renderToggleContent,
  isShowActive = true,
  onSelect,
  dataTestId = 'dropdown',
  showArrow,
  align,
  id = 'dropdown-basic',
  popperConfig,
  onToggle,
}, ref) => {
  const activeItemInItems = useMemo(() => items.find(({ value }) => value === active), [items, active]);
  const filteredList = useMemo(
    () => (isShowActive ? items : items.filter(({ value }) => value !== active)),
    [items, isShowActive, active],
  );
  const onSelectValue = useCallback((eventKey: string | null) => {
    if (eventKey) {
      onSelect?.(filteredList[+eventKey - 1]?.value);
    }
  }, [onSelect, filteredList]);

  return (
    <DropdownCore onSelect={onSelectValue} className={className} data-testid={dataTestId} onToggle={onToggle} ref={ref}>
      <DropdownCore.Toggle
        id={id}
        className={cn(classes.dropdown, classNameDropdownToggle)}
        as={DropdownToggle}
        showArrow={showArrow}
        classNameIcon={classNameIconToggle}
      >
        {typeof renderToggleContent === 'function' ? renderToggleContent(activeItemInItems) : activeItemInItems?.label}
      </DropdownCore.Toggle>
      <DropdownCore.Menu
        className={cn(classes['dropdown-menu'], classNameDropdownMenu)}
        as={DropdownMenu}
        align={align}
        popperConfig={popperConfig}
      >
        {filteredList.map(({
          label, href, external, disabled,
        }, idx) => {
          return (
            <DropdownCore.Item
              disabled={disabled}
              eventKey={(idx + 1).toString()}
              key={`${label}-${idx}`}
              className={cn(classes['menu-item'], classNameMenuItem)}
              as="div"
              data-testid={`${dataTestId}-item-${idx}`}
            >
              <Box alignItems="center" className={classes['wrap-menu-item']}>
                {href && !disabled ? (
                  <Link
                    href={href}
                    className={classes['menu-item-text']}
                    target={external ? '_blank' : undefined}
                  >
                    {label}
                  </Link>
                ) : <span className={cn(classes['menu-item-text'], { [classes.disabled]: disabled })}>{label}</span>}
              </Box>
            </DropdownCore.Item>
          );
        })}
      </DropdownCore.Menu>
    </DropdownCore>
  );
}));

export default Dropdown;
